import React from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row className="justify-content-center">
            <Col md="12">
              <h1
                className=" text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WHO CAN PARTICIPATE
              </h1>
            </Col>
            {content.map((c, i) => (
              <Col lg={4} xs={11} className="px-4">
                <Card
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: size ? "100px" : "none",
                  }}
                  className="px-3 my-3"
                >
                  <div className="d-flex">
                    <div
                      className="px-2 align-self-center rounded-circle"
                      style={{
                        minWidth: 100,
                        height: 100,
                        background: i % 2 === 0 ? "#6ac2be" : "#00a4c3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "5px solid #ffffff",
                      }}
                    >
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        style={{ width: "60px", height: "60px" }}
                        alt="about"
                        className=""
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        borderTop: "5px solid #ec33f3",
                        borderRight: "5px solid #ec33f3",
                        borderBottom: "5px solid #ec33f3",
                        borderImage: "linear-gradient(to right, #7fc1bc, #b5b84a) 1",
                        borderRadius: "30px",
                      }}
                    >
                      <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1 mt-5">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
                style={{ background: "#b5b84a" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  REGISTER NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "PROPERTY DEVELOPERS", image: "27.png", color: "#7fc1bc" },
  { title: "PROJECT OWNERS", image: "23.png", color: "#b5b84a" },
  { title: "CONSTRUCTION MANAGEMENT CONSULTANTS", image: "35.png", color: "#b5b84a" },
  { title: "GOVERNMENT BODIES & MUNICIPALITIES", image: "26.png", color: "#7fc1bc" },
  { title: "CITY PLANNERS", image: "30.png", color: "#b5b84a" },
  { title: "FACILITY MANAGERS", image: "24.png", color: "#7fc1bc" },
  { title: "DIGITAL TWIN SOLUTION PROVIDERS", image: "38.png", color: "#b5b84a" },
  { title: "DATA MANAGEMENT LEADERS", image: "40.png", color: "#7fc1bc" },
  { title: "GEOSPATIAL & GIS SOLUTION PROVIDERS", image: "36.png", color: "#b5b84a" },
  { title: "BIM & DIGITAL CONSTRUCTION SOLUTION PROVIDERS", image: "37.png", color: "#7fc1bc" },
  { title: "ARCHITECTURE, ENGINEERING, CONTRACTORS FIRMS", image: "33.png", color: "#7fc1bc" },
];

export default Attend;
