import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { content } from "./Agenda";

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={10}>
        <ScrollAnimation
          animateIn="fadeInDown"
          animateOnce={true}
          duration={0.4}
        >
          <Card
            style={{ backgroundColor: "#5ab030" }}
            className="rounded-0 my-1 py-3"
          >
            <CardBody>
              <Container>
                <Row>
                  <Col lg={12} className="align-self-center">
                    <h4 className="text-400 text-white m-0"> {d.time}</h4>
                  </Col>
                  <Col lg={12} className="align-self-center text-white ">
                    <p className="text-700">
                      <b>{d.title}</b>
                    </p>
                    {d.description && (
                      <p
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#051936",
        }}
        className="section pt-3 pb-5"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <Container>
                  <Row className="justify-content-center">
                    <AgendaCard data={content} />
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

export default Agenda;
