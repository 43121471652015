import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={10}>
        <ScrollAnimation
          animateIn="fadeInDown"
          animateOnce={true}
          duration={0.4}
        >
          <Card
            style={{ backgroundColor: "#5ab030" }}
            className="rounded-0 my-1 py-3"
          >
            <CardBody>
              <Container>
                <Row>
                  <Col lg={12} className="align-self-center">
                    <h4 className="text-400 text-white m-0"> {d.time}</h4>
                  </Col>
                  <Col lg={12} className="align-self-center text-white ">
                    <p className="text-700">
                      <b>{d.title}</b>
                    </p>
                    {d.description && (
                      <p
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <div
        style={{
          backgroundColor: "#051936",
        }}
        className="section pt-3 pb-5"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1
                  className=" text-center text-g"
                  style={{
                    fontSize: size ? "3rem" : "2rem",
                  }}
                >
                  AGENDA
                </h1>

                <h2 className="text-400 text-white text-center">
                  <b>MARCH 21</b> (Gulf Standard Time)
                </h2>

                <Container>
                  <Row className="justify-content-center">
                    <AgendaCard data={content.splice(0, 6)} />
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={3} className="text-center px-1 mt-5">
                <Button
                  href="/agenda"
                  className="rounded-0 text-400 text-white"
                  color="primary"
                  size="lg"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ background: "#5ab030" }}
                >
                  <span
                    style={{
                      color: "#fff",
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: "20px",
                    }}
                  >
                    View Full Agenda
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

export const content = [
  {
    time: "08:00",
    title: "REGISTRATION & COFFEE",
    description: ``,
  },
  {
    time: "08:50",
    title: "WELCOME NOTE FROM SAIFEE EVENTS",
    description: ``,
  },
  {
    time: "09:00",
    title: "OPENING REMARKS BY THE EVENT CHAIR",
    description: ``,
  },
  {
    time: "09:10",
    title: "KEYNOTE ADDRESS 1",
    description: ``,
  },

  {
    time: "09:20",
    title: "KEYNOTE ADDRESS 2",
    description: ``,
  },
  {
    time: "09:30",
    title:
      "VIRTUAL | Demystifying Digital Twins: How can they help in realizing a sustainable society",
    description: `
    <ul>
    <li>Introducing the concept of Digital Twins</li>
    <li>Understanding related technologies and how that helps societies and companies to adapt to changing consumer and societal needs.</li>
    <li>Concrete cases related to sustainability and our proved methodology how to start your Digital Twin journey.</li>
    
</ul>
  </ul>
  SPEAKER:
    <b>Bart Brink</b>, Global Program Director Digital Twin, <b>Royal HaskoningDHV, Netherlands</b>
    `,
  },
  {
    time: "09:50",
    title:
      "VIRTUAL | Understanding the Nature of Digital Twin – Digital Twin Challenges & Values; Empowering Businesses",
    description: `
    <ul>
    <li>Digital Twins Role in active management of “Buildings as Batteries” and “Intelligent Corridors” for Smart Cities.</li>
    <li>Understand the advantages and value over the continuum of Digital Twins from discrete to complex</li>
    
</ul>
  </ul>
  SPEAKER:
    <b>Dan Isaacs</b>, CTO, <b>Digital Twin Consortium, USA</b>
    `,
  },
  {
    time: "10:10",
    title: "MORNING COFFEE BREAK AND NETWORKING",
    description: ``,
  },
  {
    time: "10:35",
    title:
      "LEADERSHIP PANEL: How will the Digital Twin redefine a Business? Understanding the value a Digital Twin brings to the table",
    description: `
    <ul>
    <li>Understanding Standards, Policies and frameworks for Digital Twins</li>
<li>Commercial impact of Digital Twins on Asset owners</li>
<li>What it takes to implement Digital Twins – Humans and Money</li>

    </ul>
      `,
  },
  {
    time: "11:20",
    title: "VENDOR PRESENTATION",
    description: ``,
  },
  {
    time: "11:45",
    title:
      "CASE STUDY: Digital Twins - How organizations can maximize successful implementation and pitfalls to overcome.",
    description: `
    Focus on Digital Twin implementation for energy and heating projects for properties.<br/>
    <b>Anthony Tuffuor</b>, Director - Global Lead GIS and Digital Twins, <b>BuroHappold Engineering | Cities, UK</b>
    `,
  },
  {
    time: "12.05",
    title:
      "VIRTUAL | GLOBAL CASE STUDY: Helsinki 3D+ Urban Digital Twins – use cases and open services",
    description: `
      <ul>
      <li>Digital Twin (DT) platforms</li>
      <li>DT Integration to processes and services</li>
      <li>DT as Open data</li>
      <li>DT supporting city´s strategic goals</li>
      </ul><br/>
      <b>Jarmo Suomitso</b>, Helsinki 3D+ Project Manager/Architect, <b>City of Helsinki/City Executive Office, Fin land</b>
      `,
  },
  {
    time: "12.25",
    title:
      "REGIONAL CASE STUDY: AlUla's progress implementing a Digital Twin with an underlying blockchain trust layer to bring real time and predictive management of planning, development and municipal operations",
    description: `
      <ul>
      <li>Alula's Digital Twin goals</li>
      <li>The architecture and delivery Partnerships</li>
      <li>How the blockchain tryst layer fits in</li>
      <li>Integration to city control</li>
      
      </ul><br/>
      <b>Andrew Rippon</b>, Smart City Director- County Zoning & Planning Department, <b>Royal Commission for AlUla, Saudi Arabia</b>
      `,
  },
  {
    time: "12:25",
    title: "GLOBAL CASE STUDY",
    description: `
    Reserved for<b> Michal Lakomski</b>, Mayor's Proxy for Smart City, Head of Digitalization and Cybersecurity Office, <b>Smart City Poznan, Poland</b>
      `,
  },
  {
    time: "13:05",
    title: "LUNCHEON",
    description: `
    Reserved for<b> Michal Lakomski</b>, Mayor's Proxy for Smart City, Head of Digitalization and Cybersecurity Office, <b>Smart City Poznan, Poland</b>
      `,
  },
  {
    time: "14:10",
    title:
      "Seeing a Greener Future:  Natural Capital Accounting as Digital Twin",
    description: `
    <ul>
    <li>Following on the heels of the UAE and KSA Net Zero Pledges, The Green Economy is now estimated to be worth upwards of 26 Trillion Dollars</li>
    <li>One of the key challenges to a carbon economy is seeing and tracking the real outcomes of Green Investments such as Rewliding Schemes and Habitat Restorations</li>
    <li>The Natural Capital Accounting system provides a way to quantify in dollar terms the financial benefit of Ecosystem Services and Carbon Sequestration</li>
    <li>In order for the Green Economy to become a reliable revenue stream the tracking of such projects in real time is an essential means by which projects can justify their funding</li>
    <li>Case studies of AECOM’s GIS based Digital Natural Capital Account platform- How Digital Twins can enable greener futures</li>


    </ul>
    SPEAKER: <b>Steven Velegrinis</b>, Director- Urbanism & Planning, MEA, <b>AECOM, UAE</b>
      `,
  },
  {
    time: "14:30",
    title:
      "SPECIAL FOCUS: DIGITAL TWINS, FEDERATED METAVERSE AND THE OMNIVERSE: THE RISE OF INTERNET 2.0",
    description: `
    This session will outline a pathway towards BIM for Building Owners - where to start and how to prioritize your activities to ensure the project outcomes you want.<br/><br/>
    SPEAKER: <b>Simon Bromfield</b>, Territory Sales Manager – Africa, <b> Autodesk, South Africa</b>
      `,
  },
  {
    time: "14:50",
    title: "VENDOR PRESENTATION",
    description: ``,
  },
  {
    time: "15.40",
    title:
      "FIRE-SIDE CHAT | Having a deep-dive conversation on the current digital maturity level in the region and where can Digital Twins take us?",
    description: ``,
  },
  {
    time: "16:15",
    title: "VENDOR PRESENTATION",
    description: ``,
  },
  {
    time: "16:35",
    title:
      "CLOSING PANEL: Achieving maximum value from Digital Twins via understanding and implementing the tools, process, standard, policies and legalities.",
    description: `
   
    `,
  },
  {
    time: "16:55",
    title: "CLOSING REMARKS FROM THE EVENT CHAIR",
    description: `
   
    `,
  },
  {
    time: "17:00",
    title: "NETWORKING",
    description: `
   
    `,
  },
];

export default Agenda;
