import React, { useEffect } from "react";

function RegisterPage({ type }) {
  useEffect(() => {
    if (type === "WORKSHOP") {
      window.location.href =
        "https://explat-space.ams3.digitaloceanspaces.com/saifee-events/DTNZ-2024-Worksop_LR.pdf";
    } else {
      window.location.href =
        "https://explat-space.ams3.digitaloceanspaces.com/saifee-events/DTNZ-2024-Conferene_LR.pdf";
    }
  }, []);
  return <></>;
}
export default RegisterPage;
