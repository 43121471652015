import React from "react";

import Navbar from "components/Navbar.js";
import Countdown from "react-countdown";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Container, Row, Col } from "reactstrap";
function QRRedirect({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`Your In Early`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0, background: "#fff" }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} xs={12} className="text-center text-white p-5 m-5">
              <h2 className="text-center text-dark text-700 my-0 align-self-center mr-3">
                QR Code will be active on 08 - November 2022.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default QRRedirect;
