import React, { useCallback, useState } from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import ImageViewer from "react-simple-image-viewer";

import Slider from "react-slick";

function News({ news }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const ims = images.map((n) => require(`assets/photos/${n}`));
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={11} className={"ml-auto mr-auto mb-4"}>
              <Slider {...settings}>
                {images.map((n, index) => (
                  <img
                    src={require(`assets/photos/${n}`)}
                    width="100%"
                    alt="event"
                    className="px-1"
                    onClick={() => openImageViewer(index)}
                  />
                ))}
              </Slider>
            </Col>
          </Row>
          {isViewerOpen && (
            <ImageViewer
              src={ims}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  "1.jpg",
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "17.jpg",
  "18.jpg",
  "19.jpg",
  "5.jpg",
  "7.jpg",
  "8.jpg",
  "9.jpg",
  "10.jpg",
  "11.jpg",
  "12.jpg",
  "13.jpg",
  "14.jpg",
  "15.jpg",
  "16.jpg",
  "17.jpg",
  "18.jpg",
  "19.jpg",
  "20.jpg",
  "21.jpg",
  "22.jpg",
  "23.jpg",
  "24.jpg",
];
