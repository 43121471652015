import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top navbar-black`}
        style={{ minHeight: "50px", background: "#000" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" className="p-0 ml-2" tag={Link}>
              <img
                src={require(`assets/images/logo/logonav2.png`)}
                width="100%"
                style={{ maxWidth: "150px" }}
                alt="logo"
              />
              {/* <img
                src={require(`assets/images/logo/sclogo.jpg`)}
                width="100%"
                style={{ maxWidth: "120px" }}
                alt="logo"
              /> */}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler text-right navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link text-400"
                        style={{ color: "#ffffff !important" }}
                      >
                        {link.name}
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link  text-400"
                        style={{ color: "#ffffff !important" }}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link  text-400 "
                        style={{ color: "#ffffff !important" }}
                      >
                        {link.name}
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className=" text-400"
                      style={{ color: scroll ? "#ffffff" : "#ffffff" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className=" text-400"
                          onClick={toggleNavbarCollapse}
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
              <NavItem>
                <a
                  href="/register"
                  onClick={toggleNavbarCollapse}
                  tag={Link}
                  className="btn btn-primary rounded-0 nav-link navbar-item-custom text-400 text-white px-2"
                  style={{ opacity: "1" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled
                >
                  <span style={{ color: "#fff", textTransform: "none" }}>REGISTRATION CLOSED</span>
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "HOME",
    link: "/",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: "ABOUT",
    link: "about",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "ATTEND",
    link: "attend",
    page: "/",
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "AGENDA",
  //   link: "/agenda",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "PAST EVENTS",
    link: "/past-events",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: "SPEAKERS",
    link: "speakers",
    page: "/",
    // items: [
    //   {
    //     link: "/#speakers",
    //     name: "2023 SPEAKERS",
    //   },
    //   {
    //     link: "/past-speakers",
    //     name: "PAST SPEAKERS",
    //   },
    // ],
    grid: 1,
  },
  {
    name: "PARTNERS",
    link: "partners",
    page: "/",
    items: undefined,
    grid: 1,
  },
];

// const social = [
//   // {
//   //   name: "facebook",
//   //   link: "",
//   // },
//   // { name: "twitter", link: "" },
//   {
//     name: "linkedin",
//     link: "",
//   },
//   // {
//   //   name: "instagram",
//   //   link: "",
//   // },
// ];
