import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          background: `url(${require("assets/images/Sky-Tower-Auckland-–Timing-Location-and-Things-to-Do.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-primary"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className={size ? "border-right" : "border-bottom"}>
              <h1
                className="m-0 my-4 text-g text-white"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event
                <br />
                Summary
              </h1>
              <p className="text-400 text-white">
                <span className="text-700 text-i">Aotearoa New Zealand </span> is committed to build
                resilient Infrastructure for the well-being of its citizens to adapt to climate
                change and achieve Net Zero emissions. New Zealand continues to focus on Data
                ethics, AI, Digital Twins infrastructure and interoperability in key projects across
                Rail, Water, Agriculture and Cities with many projects underway.
                <br />
                <br />
                With the success of our <span className="text-700 text-i">DIGITAL TWIN</span>{" "}
                conferences in Dubai, Riyadh and London,{" "}
                <span className="text-700 text-i">Saifee Events</span> is stoked to raise the
                dialogue in Aotearoa via building a narrative that will bring the technology to
                life.
                <br />
                <br />
                <span className="text-700 text-i">
                  The NEW ZEALAND: DIGITAL TWIN 2024 conference on May 8 at the Mövenipick Hotel
                  Auckland
                </span>{" "}
                will explore the roadmap of a Digital Twin system, its impact on each stage of the
                project and explore the core values, challenges and opportunities unique to the
                priorities in NZ and how Digital Twins can deliver them; with real stories of
                successes and failures, research insights, a focus not just on what has already
                happened but the potential and where key opportunities for timely innovation appear
                to be.​ Hear from technical experts, industry leaders, researchers and everything in
                between and join us for a stand out experience designed to support the development
                of a dynamic and scalable digital twin ecosystem in New Zealand.
                <br />
                <br />
              </p>
            </Col>
            <Col md={6} className="pr-0">
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Conference
                <br />
                Format
              </h1>

              <div className="container-fluid row justify-content-center">
                {content.map((c, i) => (
                  <Col xs={12}>
                    <Card
                      style={{
                        background: c.color,
                        minHeight: size ? "80px" : "none",
                        maxWidth: "400px",
                      }}
                    >
                      <div className="d-flex p-2  ">
                        <div className="px-2 align-self-center" style={{ maxWidth: "70px" }}>
                          <img
                            src={require(`assets/icons/${c.image}`)}
                            // style={{ width: "70px" }}
                            width="100%"
                            alt="about"
                            className=""
                          />
                        </div>
                        <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "VIP KEYNOTE SPEECHES",
    image: "14.png",
    color: "#7fc1bc",
  },
  {
    title: "PROJECT CASE STUDIES AND PROOF OF CONCEPTS",
    image: "12.png",
    color: "#b5b84a",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "42.png",
    color: "#7fc1bc",
  },
  {
    title: "ROUND TABLE SESSIONS",
    image: "Asset 7.png",
    color: "#b5b84a",
  },
  {
    title: "TECHNICAL WORKSHOPS",
    image: "41.png",
    color: "#7fc1bc",
  },
  {
    title: "EXHIBITION & NETWORKING ",
    image: "43.png",
    color: "#b5b84a",
  },
];
